import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import AppSelect from 'src/components/AppSelect';
import { useOpenAI } from 'src/context/OpenAIContext';
import { OpenAIFile } from 'openai';

type CProps = {
  isOpen: boolean;
  onClose: (isRefresh?: boolean) => void;
};

interface IFormValues {
  model: string;
  training_file: string;
}

const defaultValues: IFormValues = {
  model: 'davinci',
  training_file: '',
};

const optionModels = [
  {
    value: 'davinci',
    label: 'Davinci',
  },
  {
    value: 'ada',
    label: 'Ada',
  },
  {
    value: 'babbage',
    label: 'Babbage',
  },
  {
    value: 'curie',
    label: 'Curie',
  },
];

const schema = yup.object().shape({
  training_file: yup.string().nullable().required('This is required'),
  model: yup.string().nullable().required('This is required'),
});

export function ModalAddFineTunes({ isOpen, onClose }: CProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [dataFiles, setDataFiles] = useState<OpenAIFile[]>([]);
  const { openAi } = useOpenAI();

  const handleCloseModal = () => {
    !loading && onClose();
  };

  const onHandleSubmit = async (data: IFormValues) => {
    setLoading(true);
    try {
      await openAi.createFineTune(data);
      onClose(true);
      toastSuccess('Add fine tunes successfully!');
    } catch (error) {
      toastError('Add fine tunes failed!');
      console.log(error);
    }
    setLoading(false);
  };

  const fetchDataFiles = async () => {
    setLoading(true);
    try {
      const res = await openAi.listFiles();
      setDataFiles(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataFiles();
  }, []);

  const trainingFileOptions = dataFiles?.map((el) => ({
    value: el.id,
    label: el.filename,
  }));

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <ModalContent>
            <ModalHeader>Create Fine Tunes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl display={'flex'} isInvalid={!!errors.model}>
                <FormLabel pt={2} pr={4}>
                  {'Model'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    control={control}
                    size="large"
                    name="model"
                    options={optionModels}
                  />
                  <FormErrorMessage>
                    {errors.model && errors?.model.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.training_file}
              >
                <FormLabel pt={2} pr={4}>
                  {'Training file'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    control={control}
                    size="large"
                    name="training_file"
                    options={trainingFileOptions}
                  />
                  <FormErrorMessage>
                    {errors.training_file && errors?.training_file.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                isDisabled={loading}
                onClick={handleCloseModal}
                variant="ghost"
              >
                Close
              </Button>
              <Button
                type="submit"
                isDisabled={loading}
                borderRadius={'6px'}
                colorScheme="blue"
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
