import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import rf from 'src/requests/RequestFactory';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import AppSelect from 'src/components/AppSelect';
import { IAzureBot, IBackEndAzure, ISearchIndices, ISource } from 'src/types';
import AppInput from 'src/components/AppInput';

type CProps = {
  isOpen: boolean;
  data?: IAzureBot;
  onClose: (isRefresh?: boolean) => void;
};

interface IFormValues {
  name: string;
  backend_method: string;
  search_index_name: string;
}

const defaultValues: IFormValues = {
  name: '',
  backend_method: '',
  search_index_name: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('This is required'),
  backend_method: yup.string().nullable().required('This is required'),
  search_index_name: yup.string().nullable().required('This is required'),
});

export function ModalAddAzureBot({ isOpen, onClose, data }: CProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [dataBackEnds, setDataBackEnds] = useState<IBackEndAzure[]>([]);
  const [dataSearchIndices, setDataSearchIndices] = useState<ISearchIndices[]>(
    [],
  );

  const handleCloseModal = () => {
    !loading && onClose();
  };

  const onHandleSubmit = async (dataForm: IFormValues) => {
    const { backend_method, name, search_index_name } = dataForm;

    const findBackend = dataBackEnds?.find(
      (el) => el.method === backend_method,
    );

    if (!findBackend) return;
    const approach_details = findBackend?.approach_details?.[0];
    const form = {
      source: ISource.AZURE_OPENAI,
      name,
      backend_method,
      backend_approach: findBackend?.approaches || '',
      search_index_name,
      prompt_template: approach_details?.prompt_template || '',
      query_prompt_template: approach_details?.query_prompt_template || '',
      follow_up_questions_prompt:
        approach_details?.follow_up_questions_prompt || '',
    };
    setLoading(true);
    try {
      if (data) {
        await rf.getRequest('AzureRequest').updateAzureBot(data._id, form);
        toastSuccess('Update bot azure successfully!');
      } else {
        await rf.getRequest('AzureRequest').createAzureBots(form);
        toastSuccess('Add bot azure successfully!');
      }
      onClose(true);
    } catch (error) {
      console.log(error, 'error');
      if (data) {
        toastError((error as string) || 'Update bot azure failed!');
      } else {
        toastError((error as string) || 'Add bot azure failed!');
      }
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await rf.getRequest('AzureRequest').getAzureBackEnds();
      const resp = await rf.getRequest('AzureRequest').getAzureSearchIndices();
      setDataBackEnds(res.data);
      setDataSearchIndices(resp.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      setValue('name', `${data.name}`);
      setValue('backend_method', `${data.backend_method}`);
      setValue('search_index_name', data.search_index_name);
    }
  }, [data]);

  const optionBackEnds = dataBackEnds?.map((el) => ({
    value: `${el.method}`,
    label: el.method,
  }));

  const optionSearchIndices = dataSearchIndices?.map((el) => ({
    value: el.name,
    label: el.name,
  }));

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <ModalContent>
            <ModalHeader>{data ? 'Update bot' : 'Create bot'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl display={'flex'} isInvalid={!!errors.name}>
                <FormLabel pt={2} pr={4}>
                  {'Name'}
                </FormLabel>
                <Box flex={1}>
                  <AppInput
                    width={'100%'}
                    control={control}
                    size="large"
                    placeholder="Enter name"
                    name="name"
                  />
                  <FormErrorMessage>
                    {errors.name && errors?.name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.backend_method}
              >
                <FormLabel pt={2} pr={4}>
                  {'Backend'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    control={control}
                    disabled={loading}
                    placeholder="Select backend"
                    size="large"
                    name="backend_method"
                    options={optionBackEnds}
                  />
                  <FormErrorMessage>
                    {errors.backend_method && errors?.backend_method.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.search_index_name}
              >
                <FormLabel pt={2} pr={4}>
                  {'Search Index'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    disabled={!!data || loading}
                    control={control}
                    placeholder="Select search_index_name"
                    size="large"
                    name="search_index_name"
                    options={optionSearchIndices}
                  />
                  <FormErrorMessage>
                    {errors.search_index_name &&
                      errors?.search_index_name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                isDisabled={loading}
                onClick={handleCloseModal}
                variant="ghost"
              >
                Close
              </Button>
              <Button
                type="submit"
                isDisabled={loading}
                borderRadius={'6px'}
                colorScheme="blue"
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
