import { Box } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbProps, BreadcrumbItemProps } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { BREADCRUMBS, ERoutes, ROUTES } from 'src/constants';
// import cn from 'classnames';
// import styles from 'src/styles/components/Common.module.scss';
import { getRouteByPath } from 'src/utils/route';

const AppBreadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { ...others } = props;
  const { pathname } = useLocation();
  const { name } = useParams();

  const getBreadcrumbByPath = () => {
    let items = [];
    const routeName = getRouteByPath(pathname);

    switch (routeName) {
      case ERoutes.AZURE_FILES_MANAGEMENT:
        items = [
          ...BREADCRUMBS.AZURE_FILES_MANAGEMENT,
          {
            title: name,
          },
        ];
        break;
      default:
        items = BREADCRUMBS[routeName];
        break;
    }

    const lastIndex = items.length - 1;
    const result = items.map((el, index) => {
      if (index === lastIndex) {
        return {
          title: el.title,
        };
      }
      return el;
    });
    return result;
  };

  return (
    <Box pb={'20px'}>
      <Breadcrumb {...others} items={getBreadcrumbByPath()} />
    </Box>
  );
};

export default AppBreadcrumb;
