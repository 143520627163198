import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import rf from 'src/requests/RequestFactory';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import AppSelect from 'src/components/AppSelect';
import { ILanguage } from 'src/types';
import AppInput from 'src/components/AppInput';

type CProps = {
  isOpen: boolean;
  onClose: (isRefresh?: boolean) => void;
};

interface IFormValues {
  index_name: string;
  language_code: string;
}

const defaultValues: IFormValues = {
  index_name: '',
  language_code: 'en',
};

const schema = yup.object().shape({
  index_name: yup.string().nullable().required('This is required'),
  language_code: yup.string().nullable().required('This is required'),
});

export function ModalAddSearchIndices({ isOpen, onClose }: CProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLanguage, setDataLanguage] = useState<ILanguage[]>([]);

  const handleCloseModal = () => {
    !loading && onClose();
  };

  const onHandleSubmit = async (data: IFormValues) => {
    setLoading(true);
    try {
      await rf.getRequest('AzureRequest').createSearchIndices(data);
      onClose(true);
      toastSuccess('Add search indices successfully!');
    } catch (error) {
      console.log(error, 'error');
      toastError((error as string) || 'Add search indices failed!');
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await rf.getRequest('AzureRequest').getLanguage();
      setDataLanguage(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const optionLanguage = dataLanguage?.map((el) => ({
    value: el.code,
    label: el.language,
  }));

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <ModalContent>
            <ModalHeader>Create search indices</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl display={'flex'} isInvalid={!!errors.index_name}>
                <FormLabel pt={2} pr={4}>
                  {'Name'}
                </FormLabel>
                <Box flex={1}>
                  <AppInput
                    width={'100%'}
                    control={control}
                    size="large"
                    placeholder="Enter name"
                    name="index_name"
                  />
                  <FormErrorMessage>
                    {errors.index_name && errors?.index_name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.language_code}
              >
                <FormLabel pt={2} pr={4}>
                  {'Backend'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    control={control}
                    placeholder="Select backend"
                    size="large"
                    name="language_code"
                    options={optionLanguage}
                  />
                  <FormErrorMessage>
                    {errors.language_code && errors?.language_code.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                isDisabled={loading}
                onClick={handleCloseModal}
                variant="ghost"
              >
                Close
              </Button>
              <Button
                type="submit"
                isDisabled={loading}
                borderRadius={'6px'}
                colorScheme="blue"
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
