import {
  Box,
  Spinner,
  IconButton,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import rf from 'src/requests/RequestFactory';

import { Table } from 'antd';
import moment from 'moment';
import FormUploadAzureFile from './FormUploadAzureFile';
import type { ColumnsType } from 'antd/es/table';
import { toastError, toastSuccess } from 'src/utils/notify';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ModalDetailAzureFile from './ModalDetailAzureFile';
import { IFileAzure } from 'src/types';
import AppModalConfirm from 'src/components/AppModalConfirm';
import { useParams } from 'react-router-dom';

function TableAzureFiles() {
  const [dataTable, setDataTable] = useState<IFileAzure[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<IFileAzure>();
  const [dataDelete, setDataDelete] = useState<IFileAzure | null>(null);

  const { name } = useParams();

  const fetchDataAzureFiles = async () => {
    setLoading(true);
    try {
      const res = await rf
        .getRequest('AzureRequest')
        .getAzureFiles({ search_index_name: name });

      setDataTable(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteAzureFiles = async (data: IFileAzure) => {
    setLoading(true);
    try {
      await rf
        .getRequest('AzureRequest')
        .deleteAzureFiles({ filename: data.filename, search_index_name: name });
      toastSuccess('Delete successfully!');
      setDataDelete(null);
      await fetchDataAzureFiles();
    } catch (error) {
      console.log(error);
      toastError('Delete Failed!');
    }
    setLoading(false);
  };

  const columns: ColumnsType<IFileAzure> = [
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Create At',
      dataIndex: 'creation_time',
      key: 'creation_time',
      render: (value) => {
        return moment(value).format('DD/MM/YYYY, h:mm:ss a');
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'filename',
      render: (_, data) => (
        <Flex>
          <Tooltip label="Delete">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="delete"
              onClick={() => deleteAzureFiles(data)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
          <Tooltip label="View Detail">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="info"
              onClick={() => {
                setDataDetail(data);
                setIsOpenModal(true);
              }}
              icon={<InfoCircleOutlined />}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];
  const onCloseModal = () => {
    setDataDetail(undefined);
    setIsOpenModal(false);
  };
  const onSuccessUpload = () => {
    fetchDataAzureFiles();
  };

  useEffect(() => {
    fetchDataAzureFiles();
  }, []);

  return (
    <Box>
      <Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text fontWeight={700} margin={0} fontSize={'32px'}>
            Files Management
          </Text>
        </Flex>
        <FormUploadAzureFile
          isOpen
          onSuccess={onSuccessUpload}
          searchIndexName={name || ''}
        />
        <Table
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Spinner />,
          }}
          pagination={{ pageSize: 5 }}
          dataSource={dataTable}
          rowKey={(el) => el.filename}
        />
        {isOpenModal && (
          <ModalDetailAzureFile
            search_indices={name || ''}
            isOpen={isOpenModal}
            onClose={onCloseModal}
            data={dataDetail}
          />
        )}
        <AppModalConfirm
          isOpen={!!dataDelete}
          onClose={() => setDataDelete(null)}
          disabled={loading}
          onConfirm={() => dataDelete && deleteAzureFiles(dataDelete)}
        />
      </Box>
    </Box>
  );
}

export default TableAzureFiles;
