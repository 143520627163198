/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'src/config';
import axios from 'axios';
import { setAuthorizationToRequest } from 'src/utils/utils-auth';
import Storage from 'src/utils/storage';
import { toastError } from 'src/utils/notify';

export default class BaseRequest {
  protected accessToken = '';
  constructor() {
    const accessToken = Storage.getAccessToken();
    if (accessToken) {
      this.accessToken = accessToken;
      setAuthorizationToRequest(this.accessToken);
    }
  }

  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  async get(url: string, params?: any) {
    try {
      const config = {
        params,
      };
      const response = await axios.get(this.getUrlPrefix() + url, config);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async put(url: any, data: any) {
    try {
      const response = await axios.put(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async patch(url: any, data: any) {
    try {
      const response = await axios.patch(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async post(url: any, data: any) {
    try {
      const response = await axios.post(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async delete(url: any, data?: any) {
    try {
      const config = {
        data,
      };
      const response = await axios.delete(this.getUrlPrefix() + url, config);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async upload(url: any, data?: any) {
    try {
      const config = {
        ...data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await axios.post(this.getUrlPrefix() + url, config);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async _responseHandler(response: any) {
    return response.data;
  }

  _errorNavigator(err: any) {
    if (!err.response || !err.response.status) {
      return;
    }
    switch (err.response.status) {
      case 403:
        toastError("Invalid Role !!!")
        break;
      default:
        break;
    }
  }

  async _errorHandler(err: any) {
    this._errorNavigator(err);
    // if (err.response) {
    //   console.log('===errorHandler', JSON.stringify(err.response));
    //   console.log('===errorHandler data', JSON.stringify(err.response.data));
    //   console.log(
    //     '===errorHandler status',
    //     JSON.stringify(err.response.status),
    //   );
    //   console.log(
    //     '===errorHandler headers',
    //     JSON.stringify(err.response.headers),
    //   );
    // } else {
    //   console.log('==errorHandler', JSON.stringify(err));
    // }
    if (err.response && err.response.data && err.response.data.message) {
      throw err.response.data.message;
    }
    if (err.response && err.response.data && err.response.data.error) {
      throw err.response.data.error;
    }
    throw err;
  }
}
